<template>
  <div class="container">
    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

      <breadcrumbs v-if="!isEdit"></breadcrumbs>

      <h2 class="titlePage_subtitle titlePage">
        SELECT STYLE
        <label class="form__label" v-if="checkBox">
          <input type="checkbox"
                 hidden="hidden"
                 class="form__checkbox"
                 v-model="selectAll"
                 @change="selectAllItems"
          >
          <span class="form__checkbox_icon"></span>
          <span class="form__labelTitle">Select All</span>
        </label>
      </h2>
    </section>
    <section class="item-types">
      <div class="row">
        <div class="col-md-2 col-sm-4 col-6"
             v-for="type in style"
             :key="type.id"
        >
          <label class="box" v-if="checkBox">
            <input type="checkbox"
                   hidden="hidden"
                   class="form__checkbox"
                   :value="type.id"
                   v-model="all"
                   @change="addToSelected(type)"
            >
            <span class="form__checkbox_icon"></span>
            <span class="">
              <img :src="type.icon" :alt="type.name" class="">
            </span>
            <span class="text_default">{{type.name}}</span>
          </label>

          <div class="box"
               @click="selectStyle(type)"
               :class="{'box_opacity': type.disabled}"
               v-else>
            <p class="box__imgWrap">
              <img :src="type.icon" :alt="type.name" class="box__img">
            </p>
            <p class="text_default box__title">{{type.name}}</p>
            <p class="text_red text_small" v-if="type.disabled && type.name">(restricted by size)</p>
          </div>
        </div>
      </div>
    </section>

    <button type="button"
            v-if="checkBox"
            class="btn  btn_transparent"
            @click="selectStyle"
            :disabled="Object.keys(selected).length < 1"
    >
      <svg-icon name="solid/step-forward"/> Next
    </button>
  </div>
</template>

<script>

  import router from "../../router";
  import breadcrumbs from "./../parts/breadcrumbs";
  import Vue from "vue";

  import {mapGetters, mapState} from 'vuex';
  import style from "../../store/modules/order/style";

  export default {
    name: "SelectStyle",
    components: {breadcrumbs},
    data() {
      return {
        target_id: '',
        all: [],
        selected: {},
        selectAll: false,
        width: 0,
        drop: 0,
      }
    },
    props: {
      checkBox: Boolean,
      createdOrder: Boolean
    },

    watch: {
      'all'() {
        if (this.all.length === this.style.length) {
          this.selectAll = true;
        }
        else {
          this.selectAll = false;
        }
      }
    },

    methods: {
      selectStyle(target) {
        if (target.disabled) {
          return false
        }
        if (this.checkBox) {
          this.$store.commit('createdOrder/updateStyle', this.selected);
          this.$parent.exchangeData = 'selectGrade';
          let path = this.$store.getters['app/orderBuilderPath']('style_id>' + this.selected[Object.keys(this.selected)[0]].id);

          if (Object.keys(this.selected).length > 0) {
            this.$store.dispatch('app/addBreadcrumb',
              {
                route: {
                  path: path,
                },
                name: 'Style',
                selected: this.selected
              }
            );
            router.push({path: path});
          }
        }
        else {
          this.target_id = target.id;
          if(this.isEdit) {
            this.$store.dispatch('orderPosition/updateStyle', target);
          }else {
            this.$store.commit('orderPosition/setStyle', target);
          }
        }
      },

      addToSelected(el) {
        if (!!this.selected['k' + el.id]) {
          Vue.delete(this.selected, 'k' + el.id)
        } else {
          Vue.set(this.selected, 'k' + el.id, {id: el.id, name: el.name})
        }
      },

      showPrew() {
        let d = this.$store.getters['orderPosition/orderPosition'].item.style_id;
        if (Object.keys(d).length > 0) {
          if (_.isUndefined(d[Object.keys(d)[0]].icon)) {
            Vue.set(this, 'selected', Object.assign({}, d));
          }
          else {
            Vue.set(this, 'selected',
              _.reduce(d, (memo, item) => {
                  memo['k' + item.id] = item;
                  return memo;
                }, {}
              ));
          }
          if (Object.keys(this.selected).length === 0) {
            this.selected = {}
          }
          else {
//            this.all = this.$store.getters['createdOrder/filterStyle'];
          }
        }
      },

      selectAllItems() {
        let d = this.style;
        if (this.selectAll) {
          Vue.set(this, 'selected', _.reduce(d, (memo, item) => {
              memo['k' + item.id] = item;
              return memo;
            }, {}
          ));
          this.all = [];
          _.forEach(d, (el) => {
            this.all.push(el.id);
          });
        }

        else{
          this.all = [];
          this.selected = {}
        }
      },

      checkStyleUrl(){
        if(this.createdOrder){
          this.$store.dispatch('style/getStyle', this.target_id);
        }
        else this.$store.dispatch('style/getStyleWithoutLimits', this.target_id);

      }
    },

    computed: {
      ...mapGetters({
        user: 'userList/user',
        style: 'style/style',
      }),
        ...mapGetters({
            currentStep: 'orderPosition/currentStep',
        }),
      getUserName() {
        return localStorage.getItem('nameUser');
      },
      isEdit() {
        return this.$store.getters['orderPosition/isCompleted']
      }
    },

    created() {
      this.width = this.$store.getters['orderPosition/orderPosition'].wdt;
      this.drop = this.$store.getters['orderPosition/orderPosition'].htl;
      this.showPrew();
      this.checkStyleUrl();
    },
  }
</script>

<style scoped lang="scss">

  @import "../../assets/scss/utils/vars";

  .box {
    margin-bottom: 1rem;
    display: block;
    cursor: pointer;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: 160px;
      max-height: 160px;
    }
    p, span {
      display: block;
      text-align: center;
    }
  }

  .form {
    &__labelTitle {
      color: $navy;
    }
    &__checkbox_icon {
      margin: 10px 10px 5px 0;
    }
  }

  .titlePage_subtitle {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    white-space: nowrap;
    .form {
      &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 10px;
      }

      &__checkbox_icon {
        margin: 5px;
      }

      &__labelTitle {
        font-size: $defSize;
      }
    }
  }

  .btn_transparent {
    display: block;
    margin: 0;
    position: absolute;
    bottom: 3rem;
    left: 150px;
    &:disabled {
      opacity: .6;
    }
    svg {
      margin: 0;
    }
  }


</style>
